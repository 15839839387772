import { ProvidersType } from "@/types/providers.types"
import { Team } from "@/types/teams.types"
import DisplayOwnFleet from "./DisplayOwnFleet"
import { VehicleType } from "@/types/vehicle.types"
import DisplayExternalCourier from "./DisplayExternalCourrier"
import { DraftOrder } from "@/types/draftOrder.types"
import { IOrder } from "@/types/orders.types"
import React from "react"

export interface IDisplayProviderProps {
  provider: ProvidersType
  team?: Team
  driverId?: string
  order?: DraftOrder | IOrder
  compact?: boolean
  logo?: boolean
  fontStyle?: string
  showName?: boolean
  className?: string
  withVehicle?: boolean
  vehicleType?: VehicleType
  onClick?: (e: React.MouseEvent<HTMLElement>) => any
}

// Enable provider
export const providerImage = (provider: ProvidersType) => {
  const images = {
    GRAB: "/assets/images/providerLogo/Grab.svg",
    PANDAGO: "/assets/images/providerLogo/Pandago.svg",
    LALAMOVE: "/assets/images/providerLogo/Lalamove.svg",
    ZEEK: "/assets/images/providerLogo/Zeek.svg",
    PICKUPP: "/assets/images/providerLogo/Pickupp.svg",
    QXPRESS: "/assets/images/providerLogo/Qxpress.svg",
    UBER: "/assets/images/providerLogo/Uber.svg",
    IFOOD: "/assets/images/providerLogo/Ifood.svg",
    TELEPORT: "/assets/images/providerLogo/Teleport.svg",
    GOGOX: "/assets/images/providerLogo/Gogox.svg",
    BORZO: "/assets/images/providerLogo/Borzo.svg",
    NINJAVAN: "/assets/images/providerLogo/Ninjavan.svg",
    JNT: "/assets/images/providerLogo/jnt.svg",
    RAPPI: "/assets/images/providerLogo/Rappi.svg",
    MILKRUN: "/assets/images/providerLogo/Milkrun.svg",
    TADA: "/assets/images/providerLogo/Tada.svg",
    GOGET: "/assets/images/providerLogo/goget.svg",
    KOSMO: "/assets/images/providerLogo/Kosmo.svg",
    OWN_FLEET: "/assets/images/providerLogo/Kosmo.svg",
    KOSMO_NETWORK: "/assets/images/providerLogo/Kosmo.svg",
  }

  return images[provider]
}

export const DisplayProvider: React.FC<IDisplayProviderProps> = ({
  provider,
  driverId,
  compact = false,
  logo = true,
  fontStyle = "font-semibold",
  showName = true,
  withVehicle = false,
  vehicleType,
  className = "",
  team,
  order,
}) => {
  return (
    <>
      {provider === ProvidersType.OWN_FLEET ? (
        <DisplayOwnFleet
          team={team}
          order={order}
          driverID={driverId || ""}
          compact={compact}
          fontStyle={team ? "" : fontStyle}
          className={className}
          vehicleType={vehicleType}
        />
      ) : (
        <DisplayExternalCourier
          withVehicle={withVehicle}
          provider={provider}
          team={team}
          logo={logo}
          showName={showName}
          driverId={driverId}
          compact={compact}
          fontStyle={team ? "" : fontStyle}
          className={className}
          vehicleType={vehicleType}
        />
      )}
    </>
  )
}
