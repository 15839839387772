import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { CountryKeys } from "@/types/localization.types"
import { IProviderDetails } from "@/types/organization-providers"
import { ProvidersType } from "@/types/providers.types"
import { VehicleType } from "@/types/vehicle.types"

export const COUNTRIES_WITH_3PL = [CountryKeys.SG, CountryKeys.MY, CountryKeys.PH, CountryKeys.BR, CountryKeys.HK, CountryKeys.TH, CountryKeys.ID, CountryKeys.TW] as string[]

export const PROVIDER_DETAILS: IProviderDetails[] = [
  {
    title: "Own Fleet",
    name: ProvidersType.OWN_FLEET,
    deliveryTypes: [],
    vehicles: [],
    isPodAvailable: true,
    isCodAvailable: true,
    countries: [],
    tips: {
      active: true,
      SG: { options: [3, 5, 10], maxTip: 20 },
      PH: { options: [20, 50, 100], maxTip: 100 },
      BR: { options: [4, 8, 20], maxTip: 20 },
      MY: { options: [1, 3, 5], maxTip: 20 },
      HK: { options: [10, 20, 50], maxTip: 50 },
    },
  },
  {
    title: "Grab",
    name: ProvidersType.GRAB,
    deliveryTypes: [ServerDeliveryType.INSTANT, ServerDeliveryType.FOURHOURS],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG", "PH", "TH"],
    feeStart: {
      SG: 600,
      MY: 450,
      PH: 5500,
      BR: null,
      TH: null,
    },
  },
  {
    title: "Pandago",
    name: ProvidersType.PANDAGO,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG", "MY", "PH", "HK", "TH"],
    feeStart: {
      SG: 650,
      MY: 425,
      PH: 5000,
      BR: null,
      HK: 4000,
      TH: null,
    },
  },
  {
    title: "Lalamove",
    name: ProvidersType.LALAMOVE,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN, VehicleType.LORRY],
    isPodAvailable: true,
    isCodAvailable: true,
    countries: ["SG", "MY", "BR", "TH", "PH", "ID", "HK", "TH"],
    feeStart: {
      SG: 800,
      MY: 450,
      PH: 4400,
      BR: 500,
      HK: 4500,
      TH: null,
    },
    tips: {
      active: true,
      SG: { options: [3, 5, 10], maxTip: 20 },
      PH: { options: [20, 50, 100], maxTip: 100 },
      BR: { options: [4, 8, 20], maxTip: 20 },
      MY: { options: [1, 2, 5], maxTip: 20 },
      HK: { options: [10, 20, 50], maxTip: 50 },
      TH: { options: [5, 15, 30], maxTip: 100 },
    },
  },
  {
    title: "Pickupp",
    name: ProvidersType.PICKUPP,
    deliveryTypes: [ServerDeliveryType.FOURHOURS],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG", "MY", "HK"],
    feeStart: {
      SG: 750,
      MY: 639,
      PH: null,
      BR: null,
      HK: 5500,
    },
  },
  {
    title: "Pickupp",
    name: ProvidersType.PICKUPP,
    deliveryTypes: [ServerDeliveryType.NEXT_DAY, ServerDeliveryType.NEXT_THREE_DAYS],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG", "MY"],
    feeStart: {
      SG: 330,
      MY: 330,
      PH: null,
      BR: null,
    },
    isStandardDelivery: true,
  },
  {
    title: "Qxpress",
    name: ProvidersType.QXPRESS,
    deliveryTypes: [ServerDeliveryType.FOURHOURS],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.VAN],
    isPodAvailable: false,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 659,
      MY: null,
      PH: null,
      BR: null,
    },
  },
  {
    title: "Qxpress",
    name: ProvidersType.QXPRESS,
    deliveryTypes: [ServerDeliveryType.NEXT_THREE_DAYS],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.VAN],
    isPodAvailable: false,
    isCodAvailable: false,
    countries: ["SG", "MY"],
    feeStart: {
      SG: 330,
      MY: 550,
      PH: null,
      BR: null,
    },
    isStandardDelivery: true,
  },
  {
    title: "Uber",
    name: ProvidersType.UBER,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["BR", "DE"],
    feeStart: {
      SG: null,
      MY: null,
      PH: null,
      BR: 550,
    },
    tips: {
      active: true,
      BR: { options: [4, 8, 20], maxTip: 20 },
    },
  },
  {
    title: "Gogox",
    name: ProvidersType.GOGOX,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN],
    isPodAvailable: false,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 1200,
      MY: null,
      PH: null,
      BR: null,
      HK: 5000,
    },
  },
  {
    title: "IFood",
    name: ProvidersType.IFOOD,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE],
    isPodAvailable: false,
    isCodAvailable: false,
    countries: ["BR"],
    feeStart: {
      SG: null,
      MY: null,
      PH: null,
      BR: 759,
    },
  },
  // {
  //   title: "Zeek",
  //   name: ProvidersType.ZEEK,
  //   deliveryTypes: [ServerDeliveryType.FOURHOURS],
  //   vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN],
  //   isPodAvailable: false,
  //   isCodAvailable: true,
  //   countries: ["SG", "MY"],
  // },
  {
    title: "Borzo",
    name: ProvidersType.BORZO,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR],
    isPodAvailable: true,
    isCodAvailable: true,
    countries: ["MY", "BR", "PH"],
    feeStart: {
      SG: null,
      MY: 400,
      PH: 4400,
      BR: 800,
    },
  },
  {
    title: "JNT",
    name: ProvidersType.JNT,
    deliveryTypes: [ServerDeliveryType.NEXT_DAY, ServerDeliveryType.NEXT_THREE_DAYS],
    vehicles: [VehicleType.VAN],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 330,
      MY: null,
      PH: null,
      BR: null,
    },
    isStandardDelivery: true,
  },
  {
    title: "Ninjavan",
    name: ProvidersType.NINJAVAN,
    deliveryTypes: [ServerDeliveryType.NEXT_DAY, ServerDeliveryType.NEXT_THREE_DAYS],
    vehicles: [VehicleType.VAN],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 370,
      MY: 639,
      PH: 5500,
      BR: null,
    },
    isStandardDelivery: true,
  },
  {
    title: "Rappi",
    name: ProvidersType.RAPPI,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR],
    isPodAvailable: false,
    isCodAvailable: false,
    countries: ["BR", "CO"],
    feeStart: {
      SG: null,
      MY: null,
      PH: null,
      BR: 850,
    },
  },
  {
    title: "Milkrun",
    name: ProvidersType.MILKRUN,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 825,
      MY: null,
      PH: null,
      BR: null,
    },
  },
  {
    title: "Tada",
    name: ProvidersType.TADA,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 950,
      MY: null,
      PH: null,
      BR: null,
    },
  },
  {
    title: "Goget",
    name: ProvidersType.GOGET,
    deliveryTypes: [ServerDeliveryType.INSTANT],
    vehicles: [VehicleType.MOTORCYCLE, VehicleType.CAR],
    isPodAvailable: true,
    isCodAvailable: false,
    countries: ["MY"],
    feeStart: {
      SG: null,
      MY: 1000,
      PH: null,
      BR: null,
    },
  },
  {
    title: "Teleport",
    name: ProvidersType.TELEPORT,
    deliveryTypes: [ServerDeliveryType.NEXT_DAY],
    vehicles: [VehicleType.CAR],
    isPodAvailable: false,
    isCodAvailable: false,
    countries: ["SG"],
    feeStart: {
      SG: 330,
      MY: null,
      PH: null,
      BR: null,
    },
    isStandardDelivery: true,
  },
]
